@import '@objectedge/pecai-storefront-ds/src/styles/variables';
@import '@objectedge/pecai-storefront-ds/src/styles/colors/colors';

.header {
  &__main {
    background-color: $white;
    width: 100%;
    z-index: 1002;

    .links {
      display: flex;
      align-items: center;
      height: 100%;
      a {
        margin: 0 30px;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: $secondary-500;
        cursor: pointer;
        white-space: nowrap;

        @media screen and (max-width: 1198px) {
          margin: 0 16px;
        }

        &:hover {
          color: $gray-100;
        }

        &:active {
          color: $secondary-300;
        }

        &.active {
          color: $primary-500 !important;
        }
      }
    }

    .login-dropdown {
      &__menu {
        margin-top: -10px;
      }
      &__items {
        width: 416px;
        padding: 8px 24px;
        &:first-child {
          background: $secondary-500;
          a:first-child {
            color: $primary-500;
          }
        }

        &:not(:first-child, :last-child) {
          border-bottom: 1px solid $gray-100;
        }
        a {
          display: flex;
          justify-content: center;
          color: $secondary-500;
        }
      }

      .commercial-name {
        width: 70%;
        white-space: normal;
        word-break: break-word;
        color: $secondary-500;
        cursor: pointer;
      }
      .change-account-link {
        text-decoration: underline;
        cursor: pointer;
        color: $white;
      }
    }

    .cart-container {
      position: relative;
      display: flex;
      align-items: center;
      height: 76px;

      .cart-icon {
        margin-left: 4px;
        margin-top: 4px;
      }

      .cart-quantity {
        position: absolute;
        top: 30px;
        left: 16px;
        width: 15px;
        line-height: 15px;
        border-radius: 50%;
        text-align: center;
        font-size: 8px;
        color: $secondary-500;
        background: $primary-400;
      }
    }
  }

  &__container {
    width: 100%;
    height: 76px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0px;
    padding: 0px;
    max-width: 100vw;

    .session-container {
      display: flex;
      align-items: center;
      gap: 40px;
    }
  }
}

.session-link {
  width: 100%;
  text-decoration: underline;
  margin-right: auto;
  color: $secondary-900;
  cursor: pointer;
}
