@import '@objectedge/pecai-storefront-ds/src/styles/variables';

.header {
  background-color: $white;
  width: 100%;
  z-index: 1002;

  &__main {
    display: flex;
    height: 62px;
    padding: 0px 24px;
    justify-content: space-between;
    align-items: center;
  }

  .cart-icon {
    margin-left: 4px;
    margin-top: 4px;
  }

  .cart-quantity {
    position: absolute;
    top: 10px;
    left: 15px;
    width: 15px;
    line-height: 15px;
    border-radius: 50%;
    text-align: center;
    font-size: 8px;
    color: $secondary-500;
    background: $primary-400;
  }
}

.registerButton {
  cursor: pointer;
}

.backdrop {
  background-color: rgba($secondary, 0.7);
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 1002;
}

.submenu {
  background-color: $white;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 1002;
  top: 0;
  right: 0;
  overflow: scroll;
  overscroll-behavior: contain;
  padding-bottom: 100px;

  @media screen and (min-width: 320px) {
    max-width: 250px;
  }

  &__header {
    display: flex;
    padding: 0px 24px;
    justify-content: space-between;
    height: 62px;
    align-items: center;
    border-bottom: 1px solid $gray-50;
  }

  &__content {
    padding: 0px 16px;
  }

  &__session {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 16px 0px;
    gap: 16px;

    button {
      width: 100%;
      max-width: 250px;
    }
    a {
      text-decoration: none;
      color: $secondary-500;
    }
  }

  &__logout {
    text-decoration: underline;
    color: $secondary-900;
  }

  &__items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    a {
      color: $secondary-500;
    }
  }

  .divider {
    border-width: 1px 0px 0px 0px;
    border-style: solid;
    border-color: $gray-50;
    margin: 16px;
    width: 90%;
  }
}
